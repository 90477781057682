import { useState, useEffect } from 'react';

const useDarkMode = () => {
    const [theme, setTheme] = useState('light');

    const toggleTheme = () => {
        if (theme === 'light') {
            localStorage.setItem('theme', 'dark');
            setTheme('dark');
            document.body.style.background = '#001a30';
            document.body.style.color = '#fff';
        } else {
            localStorage.setItem('theme', 'light');
            setTheme('light');
            document.body.style.background = '#fff';
            document.body.style.color = '#001a30';
        }
    }

    useEffect(() => {
        const localTheme = localStorage.getItem('theme');
        if (localTheme) {
            setTheme(localTheme);
        }
    }, [])

    return [theme, toggleTheme]
}
export default useDarkMode;