export const HTTP_STATUS_CODE_OK = 200
export const HTTP_STATUS_CODE_CREATED = 201
export const HTTP_STATUS_CODE_NO_CONTENT = 204
export const HTTP_STATUS_CODE_BAD_REQUEST = 400
export const HTTP_STATUS_CODE_UNAUTHORIZED = 401
export const HTTP_STATUS_CODE_FORBIDDEN = 403
export const HTTP_STATUS_CODE_NOT_FOUND = 404
export const HTTP_STATUS_CODE_UNPROCESSABLE_ENTITY = 422
export const HTTP_STATUS_CODE_SERVER_ERROR = 500
export const PARAMS = {
  api_key: 'ca6e1c98ad968abb291c28aac8dd7735',
}
export const BearerToken =
  'eyJhbGciOiJIUzI1NiJ9.eyJhdWQiOiJjYTZlMWM5OGFkOTY4YWJiMjkxYzI4YWFjOGRkNzczNSIsInN1YiI6IjYxNWMxNWE2YjFmNjhkMDA2NzY4NGVhMSIsInNjb3BlcyI6WyJhcGlfcmVhZCJdLCJ2ZXJzaW9uIjoxfQ.Pu93VfTONn46Fao50m9B6vVS1WVpyo_qaVI9sGJqwY8'
