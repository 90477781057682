import {create} from 'apisauce'
import {PARAMS} from './constant'
console.log(process.env)
// define the api
const api = create({
  baseURL: 'https://api.themoviedb.org/3',
  params: PARAMS,
  headers: {
    Authorization: `Bearer eyJhbGciOiJIUzI1NiJ9.eyJhdWQiOiJjYTZlMWM5OGFkOTY4YWJiMjkxYzI4YWFjOGRkNzczNSIsInN1YiI6IjYxNWMxNWE2YjFmNjhkMDA2NzY4NGVhMSIsInNjb3BlcyI6WyJhcGlfcmVhZCJdLCJ2ZXJzaW9uIjoxfQ.Pu93VfTONn46Fao50m9B6vVS1WVpyo_qaVI9sGJqwY8`,
    accept: 'application/json',
  },
})

api.addResponseTransform((response) => {
  if (response.status === 404) {
    window.location.href = '/page404'
  }
})

export async function getRequest(url, params = PARAMS) {
  return api.get(url, params)
}
export async function postRequest(url, body) {
  return api.post(url, body)
}
export async function putRequest(url, params) {
  return api.post(url, params)
}
export async function deleteRequest(url, params) {
  return api.post(url, params)
}
